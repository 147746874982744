var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"index"},[(_vm.isLoading)?_c('loading'):_vm._e(),_c('div',{staticClass:"content"},[_vm._m(0),(_vm.Listdata.isSuccess)?_c('div',{staticClass:"items"},_vm._l((_vm.Listdata.info),function(p){return _c('figure',{key:p.id},[_c('div',{staticClass:"pos"},[_c('router-link',{attrs:{"to":{
                    name:'M5Detail',
                    params:{
                        cid:p.classid,
                        id:p.id,
                    }
                },"title":p.title}},[_c('img',{key:p.id,attrs:{"src":p.titlepic}}),_c('p',[_c('span',[_vm._v(_vm._s(p.newstime))])])])],1),_c('router-link',{attrs:{"to":{
                    name:'M5Detail',
                    params:{
                        cid:p.classid,
                        id:p.id,

                    }
                },"title":p.title}},[_c('figcaption',[_vm._v(" "+_vm._s(p.title)+" ")])])],1)}),0):_vm._e()]),( _vm.Listdata.isSuccess == false)?_c('div',{staticClass:"items"},[_c('h3',[_vm._v("403 forbidden nginx")])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tit"},[_vm._v("最新更新 "),_c('span'),_c('hr')])}]

export { render, staticRenderFns }