<template >
<section class="index">
    <loading v-if="isLoading"></loading>

    <div class="content">
    <div class="tit">最新更新
        <span></span>
        <hr>
    </div>
    <div class="items" v-if="Listdata.isSuccess">


        <figure v-for="p in Listdata.info" :key="p.id">
            <div class="pos">
                <router-link :to="{
                    name:'M5Detail',
                    params:{
                        cid:p.classid,
                        id:p.id,
                    }
                }"  :title="p.title">
                    <img  :src="p.titlepic" :key="p.id"/>
                    <p><span>{{p.newstime}}</span></p>

                </router-link>
            </div>
            <router-link :to="{
                    name:'M5Detail',
                    params:{
                        cid:p.classid,
                        id:p.id,

                    }
                }"  :title="p.title">
                <figcaption>
                    {{p.title}}
                </figcaption>
            </router-link>
        </figure>

    </div>

    </div>
    <div class="items" v-if=" Listdata.isSuccess == false"><h3>403 forbidden nginx</h3></div>
 

    
</section>
</template>

<script>
import apis from "@/utils/crypt"
import loading from "../components/M5Loading"
export default {
    name:"M5Index",
    components:{
        loading
    },

     data(){
        return {
            Listdata:{
                isSuccess:false,
                info:"",
                msg:""
            },
    
         
            temp:"",
            isLoading:true
            
        }
    },
    methods:{
        showBoxMethods(){
            this.showBox = false;
        },
               
    

    }
   , 
  mounted(){
       this.$ajax.post(this.Url.IndexAddr,{
           m:apis.rsaEncode("index")
            }).then(
             response => {
            
         
            this.Listdata.info = response.data
            this.Listdata.isSuccess = true
            this.isLoading = false
            document.title = "首页"
            
        
            })}
    
}
</script>
